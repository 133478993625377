import * as React from "react";
import HcpLayout from "../components/layout";
import { Seo } from "../components/seo";
import "./get-updates.scss";
import Banner from "../components/banner";
import bannerImg from "../images/get-updates/get-updates-banner.png";
import bannerImgMobile from "../images/get-updates/get-updates-banner-mobile.png";
import TextField from "../components/textField";
import { Controller, useForm } from "react-hook-form";
import Button from "../components/button";
import waveBottomImg from "../images/get-updates/get-updated-bottom-wave.png";
import { navigate } from 'gatsby';


const axios = require("axios").default;

const patientQues = [
  {
    question: "Which of the following best describes your health?",
    id: "patient_health",
    options: [
      {
        label: "Undiagnosed",
        value: "Undiagnosed",
      },
      {
        label: "Diagnosed with diabetic nerve pain",
        value: "Diagnosed with diabetic nerve pain",
      },
      {
        label: "Diagnosed with diabetes",
        value: "Diagnosed with diabetes",
      },
      {
        label: "Other",
        value: "Other",
      },
    ],
  },
  {
    question: "What type of medication are you using for your condition?",
    id: "patient_medication",
    options: [
      {
        label: "In-office prescription patch",
        value: "In-office prescription patch",
      },
      {
        label: "Over-the-counter medicines",
        value: "Over-the-counter medicines",
      },

      {
        label: "Antidepressants/anticonvulsants",
        value: "Antidepressants/anticonvulsants",
      },
      {
        label: "Surgical procedures and implants",
        value: "Surgical procedures and implants",
      },
      {
        label: "Narcotics",
        value: "Narcotics",
      },
      {
        label: "Unknown/other",
        value: "Unknown/other",
      },
    ],
  },
  {
    question: "If taking a medication, how satisfied are you with it?",
    id: "patient_medication_satisfaction",
    options: [
      {
        label: "Extremely unsatisfied",
        value: "Extremely unsatisfied",
      },
      {
        label: "Somewhat unsatisfied",
        value: "Somewhat unsatisfied",
      },
      {
        label: "Somewhat satisfied",
        value: "Somewhat satisfied",
      },
      {
        label: "Extremely satisfied",
        value: "Extremely satisfied",
      },
      {
        label: "Other",
        value: "Other",
      },
    ],
  },
];

const caregiverQues = [
  {
    question:
      "Which of the following best describes the health of the person you care for?",
    id: "caregiver_health",
    options: [
      {
        label: "Undiagnosed",
        value: "Undiagnosed",
      },
      {
        label: "Diagnosed with diabetic nerve pain",
        value: "Diagnosed with diabetic nerve pain",
      },
      {
        label: "Diagnosed with diabetes",
        value: "Diagnosed with diabetes",
      },
      {
        label: "Other",
        value: "Other",
      },
    ],
  },
  {
    question:
      "What type of medication is your loved one using for their condition?",
    id: "caregiver_medication",
    options: [
      {
        label: "In-office prescription patch",
        value: "In-office prescription patch",
      },
      {
        label: "Over-the-counter medicines",
        value: "Over-the-counter medicines",
      },

      {
        label: "Antidepressants/anticonvulsants",
        value: "Antidepressants/anticonvulsants",
      },
      {
        label: "Surgical procedures and implants",
        value: "Surgical procedures and implants",
      },
      {
        label: "Narcotics",
        value: "Narcotics",
      },
      {
        label: "Unknown/other",
        value: "Unknown/other",
      },
    ],
  },
  {
    question:
      "Which of the following best describes the situation of the person you care for?",
    id: "caregiver_situation",
    options: [
      {
        label: "Learning about treatment options for diabetic nerve pain",
        value: "Learning about treatment options for diabetic nerve pain",
      },
      {
        label: "Currently taking a medication for diabetic nerve pain",
        value: "Currently taking a medication for diabetic nerve pain",
      },
      {
        label: "Looking for a new treatment option for diabetic nerve pain",
        value: "Looking for a new treatment option for diabetic nerve pain",
      },
      {
        label: "Talking to their doctor",
        value: "Talking to their doctor",
      },
      {
        label: "Looking for information to bring to their doctor",
        value: "Looking for information to bring to their doctor",
      },
      {
        label: "Other",
        value: "Other",
      },
    ],
  },
];

const poc = {
  patient: patientQues,
  caregiver: caregiverQues,
};

const GetUpdatesPage = () => {

  const formattedNumber = (val) => {
    val = val.replace(/\D/g, "");
    let first3digit = val.substr(0, 3);
    let second3digit = val.substr(3, 3);
    let last4digit = val.substr(6, 4);
    let formattedNumber = first3digit;
    if (second3digit) {
      formattedNumber = formattedNumber + "-" + second3digit;
    }
    if (last4digit) {
      formattedNumber = formattedNumber + "-" + last4digit;
    }
    return formattedNumber;
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    control,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      qna: {},
    },
  });

  const handleFormSubmit = (data) => {
    // Log the submitted data for debugging purposes
    // console.log(data);

    // Create a new resultData object by destructuring the submitted data.
    // Use the submitted data's qna property to access the dropdown values.
    // If a value is not present, use an empty string as the default value.
    const resultData = {
      ...data,
      patient_health: data.qna?.patient_health || "",
      patient_medication: data.qna?.patient_medication || "",
      patient_medication_satisfaction:
        data.qna?.patient_medication_satisfaction || "",
      caregiver_health: data.qna?.caregiver_health || "",
      caregiver_medication: data.qna?.caregiver_medication || "",
      caregiver_situation: data.qna?.caregiver_situation || "",
    };

    // Log the resultData object for debugging purposes
    // console.log(resultData);

    // Determine the environment for the API request based on the window's hostname
    const environment =
      window.location.hostname.replace("www.", "") !== "dpnfeet.com"
        ? "dev"
        : "prod";

    // Make an API request to /crm/get-updates with the resultData object
    axios
      .post(
        "/crm/get-updates",
        JSON.stringify({ ...resultData, environment: environment })
      )
      .then(function (response) {
        // If the API request is successful, reset the form and navigate to thank-you page
        setTimeout(() => {
          reset();
          navigate('/thank-you')
        }, 500);
      })
      .catch(function (error) {
        // If there's an error with the API request, log the error
        // console.log(error);
      });
  };

  return (
    <HcpLayout pageClass="get-updates-page">
      <section className="hero-banner">
        <Banner
          extraClass="gradient-overlay banner-inner"
          bannerImg={bannerImg}
          bannerImgMobile={bannerImgMobile}
          bannerImgAlt="Grandmother and grandchild smiling while gardening together in sun hats"
          bannerTitle="Welcome to information <nobr>and support</nobr>"
          bottomWaveDesktop={waveBottomImg}
          tagText="Actor portrayals."
        />
      </section>
      <section
        className={`sign-up__content`}
      >
        <div className="centered-content">
          <div className="main-title">
            <h2>
              This site offers educational and personalized resources you can
              use and share with your family, friends, and healthcare provider.
            </h2>
          </div>
          <div className="sign-up__info">
            <h4>
              Sign up to get educational and personalized resources you can
              share with your family, friends, and healthcare provider. Register
              now to get:
            </h4>
            <ul>
              <li>
                <strong>Periodic emails</strong> with useful tips and
                information
              </li>
              <li>
                <strong>Helpful resources,</strong> including a{" "}
                <a
                  href="/pdf/unbranded-doctor-discussion-guide.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Doctor Discussion Guide
                </a>
              </li>
              <li>
                <strong>Personal stories</strong> from others living with
                diabetic nerve pain of the feet
              </li>
            </ul>
          </div>
          <span className="sm-info">
            To sign up for information, read the{" "}
            <a
              href="https://www.averitaspharma.com/terms-of-use/"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href="https://www.averitaspharma.com/privacy-statement/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </span>
          <div className="form-wrap">
            <span className="req">*Required field.</span>
            <form
              className="form-holder"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <TextField
                label="First Name*"
                type="text"
                placeholder="First name*"
                error={errors.first}
                {...register("first", {
                  required: "This field is required",
                })}
                id="first"
              />
              <TextField
                label="Last Name*"
                type="text"
                placeholder="Last name*"
                error={errors.last}
                {...register("last", {
                  required: "This field is required",
                })}
                id="last"
              />
              <TextField
                label="Email address*"
                type="text"
                placeholder="Email address*"
                error={errors.email}
                {...register("email", {
                  required: "This field is required",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                })}
                id="email"
              />
              <TextField
                label="Confirm email address*"
                type="text"
                placeholder="Confirm email address*"
                error={errors.confirmEmail}
                {...register("confirmEmail", {
                  required: "This field is required",
                  validate: (val) => {
                    if (watch("email") != val) {
                      return "Your Email do not match";
                    }
                  },
                })}
              />
              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, ...rest } }) => (
                  <TextField
                    label="Phone Number"
                    type="text"
                    placeholder="Phone number [XXX-XXX-XXXX]"
                    onChange={(e) => onChange(formattedNumber(e.target.value))}
                    {...rest}
                    // onKeyDown={validateNumber}
                    maxLength={12}
                    id="phone"
                  />
                )}
              />

              <div className="question-wrapper">
                <p>
                  Answer the following questions so we can better personalize
                  the content we provide you.
                </p>
                <p className="option-heading">
                  <strong>Are you a patient or caregiver?</strong>
                </p>

                <div className="option-group">
                  <select className="select" {...register("patient_caregiver")}>
                    <option value="">Make a selection</option>
                    <option value="patient" name="patient">
                      Patient
                    </option>
                    <option value="caregiver" name="caregiver">
                      Caregiver
                    </option>
                  </select>
                </div>
                {poc[watch("patient_caregiver")]?.map((item, i) => (
                  <div key={item.question + i}>
                    <p className="option-heading">
                      <strong>{item.question}</strong>
                    </p>
                    <div className="option-group">
                      <select
                        className="select"
                        {...register(`qna.${item.id}`)}
                        id={item.id}
                      >
                        <option value="">Make a selection</option>
                        {item.options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ))}
              </div>
              <div className={`check-wrap ${errors.opt_in ? "error" : ""}`}>
                <div className="aligned-check">
                  <input
                    className="custom-checkbox"
                    type="checkbox"
                    {...register("opt_in", { required: true })}
                    name="opt_in"
                    id="opt_in"
                  />
                </div>
                <div className="bottom">
                  <p>*</p>
                <span>
                  I am 18 years of age or older. I would like to receive
                  information from Averitas Pharma, Inc., about educational tips,
                  treatments, programs, and resources. By selecting Sign Up, I
                  have read and agree to the{" "}
                  <a
                    href="https://www.averitaspharma.com/terms-of-use/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>{" "}
                  in accordance with your{" "}
                  <a
                    href="https://www.averitaspharma.com/privacy-statement/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </span>
                </div>
                <span className="error-msg">
                  Please check the box above acknowledging that you are 18 years
                  of age or older.{" "}
                </span>
              </div>
              <div className="btn-wrap">
                <Button
                  className="btn-primary btn-lg border-36"
                  type="submit"
                  btnTitle="Sign Up"
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    </HcpLayout>
  );
};

export default GetUpdatesPage;

export const Head = () => <Seo title="Get Updates" />;
